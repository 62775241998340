export default {
    basePlan(subscription) {
        return "pro"
    },

    labelClass(subscription) {
        return "label-warning";
    },

    accountLimit(subscription, grandfathered) {
        const amount = subscription.items.data.map(i => {
            if (i.plan.id.match(/\d+\_accounts/)) {
                return parseInt(i.plan.id.match(/\d+/))
            } else {
                return i.quantity
            }
        }).reduce((a, b) => a + b);

        if (grandfathered) {
            return ((amount < 120) ? 120 : amount)
        } else {
            return amount
        }
    },

    userLimit(plan) {
        return "Unlimited";
    },

    userLimitText(plan) {
        return "Unlimited Users"
    },

    totalCost(subscription) {
        return subscription.items.data.map(d => (d.plan.amount * d.quantity)).reduce((a, b) => a + b) / 100;
    },

    activeSubscription(subscription) {
        if (!subscription) return false;
        return (subscription.status === "trialing" || subscription.status === "active");
    }
}
