import React from 'react';
import PropTypes from 'prop-types';

import AttachToInvoice from './AttachToInvoice';

import { toast } from 'react-toastify';
import Utils from '../../Utils';
import RepairUtils from './Utils';

export default class RepairInvoice extends React.Component {
    static propTypes = {
        accountActive: PropTypes.bool.isRequired,
        repair: PropTypes.object.isRequired,
        timezone: PropTypes.string.isRequired,
        callback: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            repair: props.repair,
            invoiceAction: props.repair.billable ? "billable" : null
        };

        this.state = this.baseState;
    }

    invoiceButtonClass = (type) => {
        if (type === this.state.invoiceAction) {
            return "btn btn-primary"
        } else {
            return "btn btn-default"
        }
    };

    componentDidMount() {
        if (this.state.invoiceAction === "billable") {
            this.toggleBillablePopover(true);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.repair.repair_items) !== JSON.stringify(this.props.repair.repair_items)) {
            this.setState({ repair: { ...this.state.repair, repair_items: [...this.props.repair.repair_items] } });
        }
    }

    updateBillable = (billable) => {
        $.ajax({
            method: "PUT",
            url: `/accounts/${this.state.repair.account_id}/repairs/${this.state.repair.id}.json`,
            data: {
                repair: { billable }
            }
        }).done((repair) => {
            this.toggleBillablePopover(billable);
            this.props.callback(billable);
        }).fail((res) => {
            toast.error("Something went wrong...", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false
            });
        });
    };

    setInvoiceAction = (action) => {
        switch (action) {
            case "billable":
                let billable = this.state.invoiceAction !== "billable";
                let invoiceAction = billable ? "billable" : null;

                this.updateBillable(billable);
                this.setState({ repair: { ...this.state.repair, billable }, invoiceAction }, () => {
                    this.toggleBillablePopover(billable);
                });

                return true;
            case 'existing':
                return $("#attach-to-invoice-modal").modal("show");
            case 'new':
                if (confirm("Would you like to create a new invoice and attach this Repair to it?")) {
                    $.ajax({
                        method: "POST",
                        url: "/invoices.json",
                        data: {
                            invoice: {
                                account_id: this.state.repair.account_id,
                                due_date: moment().utc().tz(this.props.timezone).endOf("day").utc().format("YYYY-MM-DD HH:mm"),
                                type: "repairs",
                                payment_status: "draft",
                                line_items_attributes: this.lineItems()
                            }
                        }
                    }).done((invoice) => {
                        toast.success(`Repair successfully attached to Invoice #${Utils.pad(invoice.invoice_number, 5)}`, {
                            position: toast.POSITION.TOP_CENTER
                        });

                        this.setState({ repair: { ...this.state.repair, invoice } })
                    }).fail(res => {
                        toast.error("Something went wrong...", {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: false
                        });
                    });
                }
        }
    };

    lineItems = () => {
        const discount = this.state.repair.discount;
        if (RepairUtils.repairItemGrandTotal(this.state.repair) > 0.0) {
            return this.state.repair.repair_items.map(item => {
                if (parseFloat(item.price) && parseFloat(item.quantity)) {
                    return {
                        name: "Repair Item",
                        description: discount ? `${item.description} (${Utils.floatToPercentage(parseFloat(discount))} discount applied)` : item.description,
                        quantity: item.quantity,
                        repair_id: item.repair_id,
                        cost: this.state.repair.discount ? this.calculateDiscountedTotal(item.price, this.state.repair.discount) : item.price
                    }
                }
            }).filter(Boolean);
        } else {
            const description = (this.state.repair.client_notes && this.state.repair.client_notes.length > 0) ? this.state.repair.client_notes : this.state.repair.description;

            return [{
                name: "Repair",
                description: discount ? `${description} (${Utils.floatToPercentage(parseFloat(discount))} discount applied)` : description,
                quantity: 1,
                repair_id: this.state.repair.id,
                cost: this.state.repair.grand_total
            }]
        }
    };

    calculateDiscountedTotal = (price, discount) => {
        const discountAmount = parseFloat(price) * parseFloat(discount);
        return price - discountAmount;
    };

    toggleBillablePopover = (show) => {
        if (show) {
            $(this.billableButton).popover({
                content: `<div class="display-flex"><div class="display-flex justify-content-center align-items-center margin-10-right"><span class="glyphicon glyphicon-ok"></span></div><div class="flex-2">Will be added to invoice on ${moment(this.state.repair.account.next_bill_issue_date).format("MM-DD-YYYY")}</div>`,
                html: true,
                placement: "top",
                container: ".billable-popover"
            }).popover("show");
        } else {
            $(this.billableButton).popover("hide");
        }
    };

    attachToInvoiceCallback = (invoice) => {
        this.setState({ repair: { ...this.state.repair, invoice }, invoiceAction: "existing" }, () => {
            this.updateBillable(false);
        });
    };

    invoiceButtonsDisabled = () => {
        return !this.props.accountActive || (this.state.repair.grand_total === 0 && this.state.repair.repair_items.every(ri => !ri.price))
    }

    render() {
        return(
            <div className="repair-invoice-wrapper">
                { this.state.repair.invoice &&
                    <a href={`/invoices/${this.state.repair.invoice.id}`}>
                        { `#${Utils.pad(this.state.repair.invoice.invoice_number, 5)}` }
                    </a>
                }

                { !this.state.repair.invoice &&
                    <div className="overflow-x-scroll padding-10-bottom">
                        { this.state.repair.billable &&
                            <div className="billable-popover position-relative">
                            </div>
                        }
                        <div className="display-flex margin-4-left">
                            { this.state.repair.account.billing_period_type &&
                                <button
                                    className={`${this.invoiceButtonClass('billable')} margin-10-right`}
                                    ref={(b) => this.billableButton = b}
                                    onClick={(e) => this.setInvoiceAction('billable')}
                                    disabled={this.invoiceButtonsDisabled()}
                                >
                                    Add to next Service Stop Invoice
                                </button>
                            }
                            <button 
                                className={`${this.invoiceButtonClass('new')} margin-10-right`} 
                                onClick={(e) => this.setInvoiceAction('new')}
                                disabled={this.invoiceButtonsDisabled()}
                            >
                                Attach to new Invoice
                            </button>
                            <button 
                                className={`${this.invoiceButtonClass('existing')}`} 
                                onClick={(e) => this.setInvoiceAction('existing')}
                                disabled={this.invoiceButtonsDisabled()}
                            >
                                Attach to existing Invoice
                            </button>
                        </div>
                    </div>
                }
                <AttachToInvoice
                    repair={this.state.repair}
                    lineItems={this.lineItems()}
                    callback={this.attachToInvoiceCallback}
                />
            </div>
        )
    }
}
