import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';

import { ShowRepairContext } from '../ShowRepair';

import AppointmentModal from '@main/components/Appointments/AppointmentModal';

import { toast } from 'react-toastify'

const RepairItemAppointment = (props) => {
    const context = useContext(ShowRepairContext);
    let modalEl;

    const showModal = () => {
        if (context.repair.account.active) {
            modalEl.showModal();
        } else {
            toast.error("This account must be active in order to schedule an Appointment", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    };

    const apptCallback = (appointment) => {
        const repairItem = { ...props.repairItem, appointment }

        context.updateRepairItem(repairItem, props.index);
    };

    const findTech = () => {
        return context.techs.find(t => t.id.toString() === props.appointment.user_id.toString());
    };

    return(
        <Fragment>
            { (!props.appointment && !context.isTech()) &&
                <div className="display-flex justify-content-center">
                    <button className="btn btn-primary" onClick={showModal}>
                        Schedule
                    </button>
                </div>
            }
            { props.appointment &&
                <div className="display-flex flex-column">
                    <div className="display-flex justify-content-space-between">
                        <h5 className="no-margin line-height-normal">
                            Technician:
                        </h5>
                        <div className="margin-5-left">
                            { findTech().name }
                        </div>
                    </div>

                    <div className="width-100">
                        <hr/>
                    </div>

                    <div className="display-flex justify-content-space-between">
                        <h5 className="no-margin line-height-normal">
                            Start Time:
                        </h5>
                        <div className="margin-5-left">
                            { moment(props.appointment.start).tz(context.currentCompany.timezone).format("MM/DD/YYYY hh:mm A") }
                        </div>
                    </div>

                    <div className="width-100">
                        <hr/>
                    </div>

                    <div className="display-flex justify-content-space-between">
                        <h5 className="no-margin line-height-normal">
                            End Time:
                        </h5>
                        <div className="margin-5-left">
                            { moment(props.appointment.end).tz(context.currentCompany.timezone).format("MM/DD/YYYY hh:mm A") }
                        </div>
                    </div>

                    <div className="width-100">
                        <hr/>
                    </div>

                    <div className={`display-flex justify-content-center align-items-center ${props.appointment.completed && "margin-20-bottom"}`}>
                        <h4 className="no-margin line-height-normal">
                            Completed:
                        </h4>
                        <div className="display-flex margin-10-left font-2em">
                            { props.appointment.completed &&
                                <span className="glyphicon glyphicon-ok"></span>
                            }
                            { !props.appointment.completed &&
                                <span className="glyphicon glyphicon-remove"></span>
                            }
                        </div>
                    </div>

                    { (!props.appointment.completed && !context.isTech()) &&
                        <div className="display-flex justify-content-center margin-10-top">
                            <button className="btn btn-default" onClick={showModal}>
                                <span className="glyphicon glyphicon-pencil margin-5-right"></span>
                                Edit Appointment
                            </button>
                        </div>
                    }
                    { props.appointment.completed &&
                        <Fragment>
                            <div className="display-flex justify-content-space-between">
                                <h5 className="no-margin line-height-normal">
                                    In Time:
                                </h5>
                                <div className="margin-5-left">
                                    { moment(props.repairItem.in_time).tz(context.currentCompany.timezone).format("MM/DD/YYYY hh:mm A") }
                                </div>
                            </div>

                            <div className="width-100">
                                <hr/>
                            </div>

                            <div className="display-flex justify-content-space-between">
                                <h5 className="no-margin line-height-normal">
                                    Out Time:
                                </h5>
                                <div className="margin-5-left">
                                    { moment(props.repairItem.out_time).tz(context.currentCompany.timezone).format("MM/DD/YYYY hh:mm A") }
                                </div>
                            </div>

                            <div className="width-100">
                                <hr/>
                            </div>
                        </Fragment>
                    }
                </div>
            }

            <AppointmentModal
                appointment={props.appointment}
                techs={context.techs}
                timezone={context.currentCompany.timezone}
                repairItem={props.repairItem}
                apptCallback={apptCallback}
                ref={(e) => modalEl = e}
            />
        </Fragment>
    )
};

RepairItemAppointment.propTypes = {
    index: PropTypes.number.isRequired,
    repairItem: PropTypes.object.isRequired,
    appointment: PropTypes.object
}

export default RepairItemAppointment
