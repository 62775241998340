import React from 'react';
import PropTypes from 'prop-types';

import AppointmentModal from '../Appointments/AppointmentModal';

import { toast } from 'react-toastify';

export default class ScheduleRepair extends React.Component {
    static propTypes = {
        techs: PropTypes.array.isRequired,
        timezone: PropTypes.string.isRequired,
        repair: PropTypes.object,
        account: PropTypes.object.isRequired,
        appointmentCallback: PropTypes.func,
        isNotAdmin: PropTypes.bool.isRequired,
        currentUser: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            appointment: props.appointment
        }
    }

    showAppointmentModal = () => {
        if (this.props.account.active === false) {
            toast.error("This Account must be active in order to schedule a Repair", {
                position: toast.POSITION.TOP_CENTER
            })

            return false
        }

        this.setState({ ...this.state });
        $("#appointment-modal").modal("show");
    };

    apptCallback = (appointment) => {
        if (!appointment.start.length > 0 || !appointment.end.length > 0) {
            appointment = null;
        }

        this.setState({ appointment }, () => {
            if (this.props.appointmentCallback) {
                if (appointment) {
                    const { account, address, city, state, zip, ...filteredAppointment } = appointment
                    this.props.appointmentCallback(filteredAppointment);
                }
            }
        });
    };

    markCompleted = () => {
        if (confirm("Are you sure you would like to mark this repair's appointment as completed?")) {
            const completedOn = moment().tz(this.props.timezone).utc().format()
            $.ajax({
                method: "PUT",
                url: "/appointments/" + this.state.appointment.id,
                data: {
                    appointment: {
                        completed_at: completedOn
                    }
                }
            }).done(() => {
                let appointment = { ...this.state.appointment, completed_at: completedOn };
                this.setState({ appointment });
            });
        }
    };

    userName = () => {
        const user = this.props.techs.find(tech => tech.id === parseInt(this.state.appointment.user_id));

        if (user) {
            return user.name
        } else {
            return "";
        }
    };

    notificationValue = () => {
        if (this.state.appointment) {
            if (!this.state.appointment.id) {
                return this.state.appointment.notification ? "new" : null
            } else {
                return this.state.appointment.notification ? "update" : null
            }
        }
    };

    render() {
        return(
            <div className="schedule-repair">
                { !this.state.appointment &&
                    <div className="display-flex justify-content-center">
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={this.showAppointmentModal}
                        >
                            Schedule
                        </button>
                    </div>
                }
                { this.state.appointment && !this.state.appointment.completed_at &&
                    <div className="appt-details display-flex flex-column align-items-center">
                        <div>
                            <label className="margin-5-right">
                                Technician:
                            </label>
                            <span>{ this.userName() }</span>
                        </div>
                        <div className="times-display display-flex">
                            <label className="time-label">
                                Start:
                            </label>
                            <span className="time-value">
                                { moment(this.state.appointment.start).tz(this.props.timezone).format("MM-DD-YYYY hh:mm A") }
                            </span>
                            <label className="time-label">
                                End:
                            </label>
                            <span className="time-value">
                                { moment(this.state.appointment.end).tz(this.props.timezone).format("MM-DD-YYYY hh:mm A") }
                            </span>
                        </div>
                        { (!this.props.isNotAdmin || this.state.appointment.user_id === this.props.currentUser.id) &&
                            <div className="margin-5-top display-flex">
                                <button
                                    className="btn btn-primary margin-10-right"
                                    type="button"
                                    onClick={this.showAppointmentModal}
                                >
                                    Edit
                                </button>
                                { this.state.appointment.id &&
                                    <button
                                        className="btn btn-success"
                                        type="button"
                                        onClick={this.markCompleted}
                                    >
                                        Mark Completed
                                    </button>
                                }
                            </div>
                        }
                        { !this.props.repair.id &&
                            <div className="margin-5-top">
                                (Appointment will be created once repair is saved)
                            </div>
                        }
                    </div>
                }
                { this.state.appointment && this.state.appointment.completed_at &&
                    <div className="appt-details display-flex flex-column align-items-center">
                        <h5>
                            Technician:
                        </h5>
                        <div>
                            { this.userName() }
                        </div>
                        <h5>
                            <span className="glyphicon glyphicon-ok margin-10-right"></span>Completed On:
                        </h5>
                        <div>
                            { moment(this.state.appointment.completed_at).format("MM-DD-YYYY - hh:mm A") }
                        </div>
                    </div>
                }

                <AppointmentModal
                    appointment={this.state.appointment}
                    techs={this.props.isNotAdmin ? [this.props.currentUser] : this.props.techs}
                    timezone={this.props.timezone}
                    type="repair"
                    repair={this.props.repair}
                    account={this.props.account}
                    apptCallback={this.apptCallback}
                    closeModalCallback={this.apptCallback}
                    isNotAdmin={this.props.isNotAdmin}
                />
            </div>
        )
    }
}
