import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { HashRouter as Router, Route, Link } from 'react-router-dom';

import ReportButtons from './ReportButtons';
import ServiceTechCommissions from './ServiceTech/Commissions';
import ChemicalsUsed from './Chemicals/ChemicalsUsed';
import RouteReminders from './RouteReminders/Reminders';
import Appointments from './Appointments/Index';
import Zones from './Zones/Index';
import IncomeExpenses from './IncomeExpenses/Index';
import RouteOptimizer from './RouteOptimizer/Index';

import ActivateMapbox from '@shared/components/ActivateMapbox';

export default class ReportsRoot extends React.Component {
    static propTypes = {
        reports: PropTypes.array.isRequired,
        service_techs: PropTypes.array.isRequired,
        current_company: PropTypes.object.isRequired,
        company_settings: PropTypes.object.isRequired,
        is_not_admin: PropTypes.bool.isRequired,
        timezone: PropTypes.string.isRequired,
        chemicals: PropTypes.object.isRequired,
        base_plan: PropTypes.string.isRequired,
        default_tasks: PropTypes.array.isRequired,
        pusher_key: PropTypes.string.isRequired,
        environment: PropTypes.string.isRequired
    }

    render() {
        const CommissionsReport = (props) => {
            return(
                <ServiceTechCommissions
                    serviceTechs={this.props.service_techs}
                    isNotAdmin={this.props.is_not_admin}
                    timeZone={this.props.timezone}
                />
            )
        };

        const ChemicalsUsedReport = () => {
            return(
                <ChemicalsUsed
                    chemicals={this.props.chemicals}
                />
            )
        };

        const RouteRemindersReport = () => {
            return(
                <RouteReminders
                    serviceTechs={this.props.service_techs}
                    timezone={this.props.timezone}
                />
            )
        };

        const AppointmentsReport = () => {
            return(
                <Appointments
                    timezone={this.props.timezone}
                    isNotAdmin={this.props.is_not_admin}
                    defaultTasks={this.props.default_tasks}
                    currentUser={this.props.current_user}
                    company={this.props.current_company}
                    companySettings={this.props.company_settings}
                />
            )
        }

        const ZonesReport = () => {
            return(
                <Zones
                    techs={this.props.service_techs}
                    currentCompany={this.props.current_company}
                />
            )
        };

        const IncomeExpensesReport = () => {
            return(
                <IncomeExpenses
                    currentCompany={this.props.current_company}
                />
            )
        };

        const RouteOptimizerReport = () => {
            if (this.props.current_company.mapbox_api_key && this.props.current_company.mapbox_api_key.length > 0) {
                return(
                    <RouteOptimizer
                        currentCompany={this.props.current_company}
                        companySettings={this.props.company_settings}
                        techs={this.props.service_techs}
                        pusherKey={this.props.pusher_key}
                        environment={this.props.environment}
                    />
                )
            } else {
                return(
                    <ActivateMapbox />    
                )
            }
        };

        return (
            <Router>
                <div>
                    <div className="row page-header nested-row-header no-margin-top">
                        <div className="col-md-12 report-button-wrapper">
                            <ReportButtons
                                reports={this.props.reports}
                                isNotAdmin={this.props.is_not_admin}
                            />
                        </div>
                    </div>

                    <Route exact path="/" render={() => {
                        return(
                            <h4 className="text-center">
                                Select a report
                            </h4>
                        )
                    }} />
                    <Route path="/service-tech-commissions" render={CommissionsReport} />
                    { !this.props.is_not_admin &&
                        <Fragment>
                            <Route path="/chemicals-used" render={ChemicalsUsedReport} />
                            <Route path="/appointments" render={AppointmentsReport} />
                            <Route path="/zones" render={ZonesReport} />
                            <Route path="/income-expenses" render={IncomeExpensesReport} />
                            <Route path="/route-optimizer" render={RouteOptimizerReport} />
                        </Fragment>
                    }
                    <Route path="/route-reminders" render={RouteRemindersReport} />
                </div>
            </Router>
        )
    }
}
