import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import SubUtils from './SubUtils';

export default class PricingInfo extends React.Component {
    static propTypes = {
        plan: PropTypes.string.isRequired,
        buttonTxt: PropTypes.string.isRequired,
        grandfathered: PropTypes.bool.isRequired,
        minAmount: PropTypes.number,
        callback: PropTypes.func,
        updateCallback: PropTypes.func,
        activePlan: PropTypes.string,
        selectedAccounts: PropTypes.string,
        buttonDisabled: PropTypes.bool
    }

    constructor(props) {
        super(props);

        this.baseState = {
            selectedAccounts: props.selectedAccounts || 50,
        }

        if (this.minAmount() > this.baseState.selectedAccounts) {
            this.baseState.selectedAccounts = this.minAmount();
        }

        this.state = this.baseState;
    }

    handleChange = (e) => {
        this.setState({ selectedAccounts: e.target.value }, () => {
            if (this.props.updateCallback) {
                this.props.updateCallback(this.props.plan, this.state.selectedAccounts);
            }
        });
    };

    submit = () => {
        window.localStorage.setItem("activePlan", this.props.plan);
        window.localStorage.setItem("selectedAccounts", this.state.selectedAccounts);

        window.location = "/onboarding/sign_up";
    };

    callback = () => {
        if (this.props.callback) {
            this.props.callback(this.props.plan, this.state.selectedAccounts)
        } else {
            this.submit();
        }
    };

    minAmount = () => {
        if (!this.props.minAmount || this.props.minAmount <= 50) {
            return 50
        } else {
            const remainder = this.props.minAmount % 5;

            return remainder === 0 ? this.props.minAmount : this.props.minAmount + (5 - remainder)
        }
    };

    pricePerMonth = () => {
        if (this.props.grandfathered) {
            if (this.props.selectedAccounts <= 120) {
                return "$120/month"
            } else {
                const diff = (this.props.selectedAccounts - 120) * 0.75
                return `$${120 + diff}/month`
            }
        } else {
            return `$${this.props.selectedAccounts}/month`
        }
    };

    render() {
        return(
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="text-center">
                            { this.state.selectedAccounts } Accounts
                        </h3>

                        <h4 className="text-center width-100">
                            { SubUtils.userLimitText(this.props.plan) }
                        </h4>

                        <h4 className="text-center width-100">
                            { this.pricePerMonth() }
                        </h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                        <input
                            className="no-margin-bottom"
                            type="range"
                            min={this.minAmount()}
                            max="1000"
                            step="5"
                            onChange={this.handleChange}
                            onInput={this.handleChange}
                            value={this.state.selectedAccounts}
                        />
                        <div className="display-flex justify-content-center slider-label">
                            (use slider to adjust number of accounts)
                        </div>
                    </div>
                </div>
                <div className="row margin-20-top">
                    <div className="col-md-12 display-flex justify-content-center">
                        <button
                            className="btn btn-lg btn-success"
                            onClick={this.callback}
                            disabled={this.props.buttonDisabled}
                        >
                            { this.props.buttonTxt }
                        </button>
                    </div>
                </div>
            </Fragment>
        )
    }
}
