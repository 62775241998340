import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ValidationMessage from './ValidationMessage';

export default class Step3 extends React.Component {
    static propTypes = {
        getMasterState: PropTypes.func.isRequired,
        updateMasterState: PropTypes.func.isRequired,
        timezone: PropTypes.string.isRequired,
        base_plan: PropTypes.string.isRequired,
        billing_period_type: PropTypes.string,
        rate_type: PropTypes.string,
        automatic_payments: PropTypes.bool,
        invoice_delivery: PropTypes.string,
        next_billing_date: PropTypes.string,
        billing_start_date: PropTypes.string,
        generate_invoices_for_service_in: PropTypes.string,
        days_to_issue_bill: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        days_til_due_date: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        invoiceIntegration: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            billingEnabled: !!props.billing_period_type,
            billing_period_type: props.billing_period_type,
            rate_type: props.rate_type,
            automatic_payments: props.automatic_payments,
            invoice_delivery: props.delivery_method,
            base_cost: props.base_cost,
            next_billing_date: props.next_billing_date,
            billing_start_date: props.billing_start_date,
            generate_invoices_for_service_in: props.generate_invoices_for_service_in,
            days_to_issue_bill: props.days_to_issue_bill,
            days_til_due_date: props.days_til_due_date,
        }

        const validations = {};
        Object.keys(this.baseState).forEach(key => {
            if (key.match(/\_/)) {
                validations[key] = false;
            }
        });

        this.baseState.validations = validations;

        this.state = this.baseState;
    }

    componentWillReceiveProps(props) {
        let { billing_period_type, rate_type, base_cost, next_billing_date, billing_start_date, generate_invoices_for_service_in, days_to_issue_bill, days_til_due_date, invoice_delivery, automatic_payments } = props;

        this.setState({ billing_period_type, rate_type, base_cost, next_billing_date, billing_start_date, generate_invoices_for_service_in, days_to_issue_bill, days_til_due_date, automatic_payments, invoice_delivery });
    }

    componentDidMount() {
        this.enableDateTimePickers();

        if (this.state.next_billing_date) {
            $(this.nextBillingDateInput).val(moment(this.state.next_billing_date, "YYYY-MM-DD").format("MM-DD-YYYY"));
        }

        if (this.state.billing_start_date) {
            $(this.billingStartDateInput).val(moment(this.state.billing_start_date, "YYYY-MM-DD").format("MM-DD-YYYY"));
        }
    }

    isValidated() {
        const validations = {};

        ["billing_period_type", "base_cost", "next_billing_date", "billing_start_date", "generate_invoices_for_service_in", "days_to_issue_bill", "days_til_due_date"].forEach(key => {
            if (this.state.billingEnabled && (this.state[key] || "").length < 1) {
                validations[key] = true;
            } else {
                validations[key] = false;
            }
        });

        if (this.state.billing_period_type === "monthly" && this.state.generate_invoices_for_service_in == "past") {
            validations["rate_type"] = !this.state.rate_type;
        }

        this.setState({ ...this.state, validations });

        return !Object.values(validations).includes(true);
    }

    enableDateTimePickers = () => {
        $(".datetime-picker").datetimepicker({
            ignoreReadonly: true,
            allowInputToggle: true,
            format: "MM-DD-YYYY"
        });

        $(".datetime-picker").on("dp.change", (e) => {
            const val = moment(e.target.value, "MM-DD-YYYY").format("YYYY-MM-DD");
            this.props.updateMasterState({ [e.target.name]: val });
        });
    };

    humanizeBillingPeriodType = () => {
        if (this.state.billing_period_type === "4 weeks" || this.state.billing_period_type === "weekly" || !this.state.billing_period_type || this.state.rate_type === "per_stop") {
            return "stop";
        } else if (this.state.billing_period_type === "monthly") {
            return "month";
        }
    };

    rateTypeValue = (generateInvoiceForServiceIn) => {
        if (this.state.billing_period_type === "monthly" && generateInvoiceForServiceIn === "past") {
            return this.state.rate_type
        } else {
            return null
        }
    };

    enableBilling = () => {
        this.setState({ billingEnabled: true }, () => {
            this.enableDateTimePickers();
        });
    };

    render() {
        return(
            <div className="row margin-20-top margin-20-bottom">
                <div className="input-row">
                    <div className="col-sm-4 input-field">
                        <span className="field-label">
                            Automatic Billing Enabled:
                        </span>
                    </div>
                    <div className="col-sm-8 input-field">
                        <div className="field-description">
                            Would you like AquaSuite to automatically generate an invoice for this customer (for their pool service and any billable service items) when needed?
                        </div>
                        <div className="margin-10-top">
                            <button
                                className={`btn ${(this.state.billingEnabled !== null && this.state.billingEnabled) ? "btn-primary" : "btn-default"} margin-10-right`}
                                onClick={() => this.enableBilling()}
                                ref={(e) => this.billingYesButton = e}
                            >
                                Yes
                            </button>
                            <button
                                className={`btn ${this.state.billingEnabled ? "btn-default" : "btn-primary"}`}
                                onClick={() => {
                                    this.setState({ billingEnabled: false });
                                    this.props.updateMasterState({ billing_period_type: null, rate_type: null, base_cost: null, next_billing_date: null, billing_start_date: null, generate_invoices_for_service_in: null, days_to_issue_bill: null, days_til_due_date: null, automatic_payments: false, invoice_delivery: null, validations: { ...this.baseState.validations } });
                                }}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
                { this.state.billingEnabled &&
                    <div>
                        { this.props.invoiceIntegration === "stripe" &&
                            <Fragment>
                                <div className="input-row">
                                    <div className="col-sm-4 input-field">
                                        <span className="field-label">
                                            Automatic Payments:
                                        </span>
                                    </div>
                                    <div className="col-sm-8 input-field">
                                        <div className="field-description margin-10-bottom">
                                            Does this Customer wish to have their default Credit Card stored on file automatically charged after you review their invoices when they are generated for service?
                                        </div>
                                        <div className="margin-10-top">
                                            <button
                                                className={`btn ${this.state.automatic_payments ? "btn-primary" : "btn-default"} margin-10-right`}
                                                onClick={() => this.props.updateMasterState({ automatic_payments: true })}
                                            >
                                                Yes
                                            </button>
                                            <button
                                                className={`btn ${this.state.automatic_payments ? "btn-default" : "btn-primary"}`}
                                                onClick={() => this.props.updateMasterState({ automatic_payments: false })}
                                            >
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-row">
                                    <div className="col-sm-4 input-field">
                                        <div className="field-label">
                                            Invoice Delivery:
                                        </div>
                                    </div>
                                    <div className="col-sm-8 input-field">
                                        <div className="field-description margin-10-bottom">
                                            How does this Customer prefer to have their invoices delivered to them?
                                        </div>
                                        <div className="form-group col-md-6 no-margin no-padding">
                                            <select
                                                className="form-control"
                                                value={this.state.invoice_delivery || ""}
                                                onChange={(e) => this.props.updateMasterState({ invoice_delivery: e.target.value })}
                                            >
                                                <option value="">Select delivery method...</option>
                                                <option value="email">Email</option>
                                                <option value="text message">Text Message</option>
                                                <option value="snail mail">Snail Mail</option>
                                                <option value="in person">In Person</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        }
                        <div className="input-row">
                            <div className="col-sm-4 input-field">
                                <span className="field-label">Service Occurrence:</span>
                            </div>
                            <div className="col-sm-8 input-field">
                                <div className="field-description margin-10-bottom">
                                    Will this customers pool get serviced before, or after their bill for pool service is issued?
                                </div>
                                <div className="form-group no-margin col-md-6 no-padding">
                                    <select
                                        className="form-control"
                                        onChange={(e) => this.props.updateMasterState({ generate_invoices_for_service_in: e.target.value, rate_type: this.rateTypeValue(e.target.value) })}
                                        value={this.state.generate_invoices_for_service_in || ""}
                                    >
                                        <option value="">Select service occurrence...</option>
                                        <option value="past">Before</option>
                                        <option value="future">After</option>
                                    </select>
                                </div>
                                <ValidationMessage condition={this.state.validations.generate_invoices_for_service_in} message="Must set the Service Occurrence" />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="col-sm-4 input-field">
                                <span className="field-label">
                                    Billing Frequency:
                                </span>
                            </div>
                            <div className="col-sm-8 input-field">
                                <div className="field-description margin-10-bottom">
                                    How often does AquaSuite need to automatically generate a bill for this customer for their pool service?
                                </div>
                                <div className="form-group no-margin col-md-6 no-padding">
                                    <select
                                        className="form-control"
                                        onChange={(e) => this.props.updateMasterState({ billing_period_type: e.target.value }) }
                                        value={this.state.billing_period_type || ""}
                                    >
                                        <option value="">Select billing frequency...</option>
                                        <option value="weekly">Every week</option>
                                        <option value="4 weeks">Every 4 weeks</option>
                                        <option value="monthly">Same day every month</option>
                                    </select>
                                </div>
                                <ValidationMessage condition={this.state.validations.billing_period_type} message="Must select Billing Frequency" />
                            </div>
                        </div>
                        { this.state.billing_period_type === "monthly" && this.state.generate_invoices_for_service_in === "past" &&
                            <div>
                                <div className="input-row">
                                    <div className="col-sm-4 input-field">
                                        <span className="field-label">
                                            Rate Type:
                                        </span>
                                    </div>
                                    <div className="col-sm-8 input-field">
                                        <div className="field-description margin-10-bottom">
                                            Will this customer be charged per service stop or a flat rate every month?
                                        </div>
                                        <div className="form-group no-margin col-md-6 no-padding">
                                            <select
                                                className="form-control"
                                                onChange={(e) => this.props.updateMasterState({ rate_type: e.target.value }) }
                                                value={this.state.rate_type || ""}
                                            >
                                                <option value="">Select rate type...</option>
                                                <option value="per_stop">Per Stop</option>
                                                <option value="flat_rate">Flat Rate</option>
                                            </select>
                                        </div>
                                        <ValidationMessage condition={this.state.validations.rate_type} message="Must select Rate Type" />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="input-row">
                            <div className="col-sm-4 input-field">
                                <span className="field-label">Service Cost</span>
                            </div>
                            <div className="col-sm-8 input-field">
                                <div className="field-description margin-10-bottom">
                                    How much does this customer need to be charged for service every { this.humanizeBillingPeriodType() }?
                                </div>
                                <div className="form-group no-margin no-padding col-md-4">
                                    <div className="input-group">
                                        <div className="input-group-addon">
                                            <span className="glyphicon glyphicon-usd"></span>
                                        </div>
                                        <input
                                            type="number"
                                            min="1"
                                            className="form-control"
                                            onChange={(e) => this.props.updateMasterState({ base_cost: e.target.value }) }
                                            value={this.state.base_cost || ""}
                                        />
                                    </div>
                                </div>
                                <ValidationMessage condition={this.state.validations.base_cost} message="Must set Service Cost" />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="col-sm-4 input-field">
                                <span className="field-label">Next Billing Period:</span>
                            </div>
                            <div className="col-sm-8 input-field">
                                <div className="field-description margin-10-bottom">
                                    When will this customers next billing period start?
                                </div>
                                <div className="form-group no-margin col-md-6 no-padding">
                                    <input
                                        ref={(input) => this.nextBillingDateInput = input}
                                        type="text"
                                        placeholder="mm/dd/yyyy"
                                        className="form-control datetime-picker"
                                        name="next_billing_date"
                                        readOnly
                                    />
                                </div>
                                <ValidationMessage condition={this.state.validations.next_billing_date} message="Must set Next Billing Date" />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="col-sm-4 input-field">
                                <div className="field-label">Billing Start Date:</div>
                            </div>
                            <div className="col-sm-8 input-field">
                                <div className="field-description margin-10-bottom">
                                    When should AquaSuite to start recording any billable service stops for this customer?
                                </div>
                                <div className="form-group no-margin col-md-6 no-padding">
                                    <input
                                        ref={(input) => this.billingStartDateInput = input}
                                        type="text"
                                        placeholder="mm/dd/yyyy"
                                        className="form-control datetime-picker"
                                        name="billing_start_date"
                                        readOnly
                                    />
                                </div>
                                <ValidationMessage condition={this.state.validations.billing_start_date} message="Must select Billing Start Date" />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="col-sm-4 input-field">
                                <span className="field-label">
                                    Days to Issue Bill In Advance:
                                </span>
                            </div>
                            <div className="col-sm-8 input-field">
                                <div className="field-description margin-10-bottom">
                                    How many days would you like to have this customers bill for pool service generated before their billing period begins? (Make this value 0 if you wish to generate this customers bill the day their billing period begins)
                                </div>
                                <div className="form-group no-margin col-sm-4 no-padding">
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={this.state.days_to_issue_bill || ""}
                                        onChange={(e) => this.props.updateMasterState({ days_to_issue_bill: e.target.value })}
                                    />
                                </div>
                                <ValidationMessage condition={this.state.validations.days_to_issue_bill} message="Must set how many days to issue bill in advanced" />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="col-sm-4 input-field">
                                <span className="field-label">
                                    Days Til Invoice Becomes Due:
                                </span>
                            </div>
                            <div className="col-sm-8 input-field">
                                <div className="field-description margin-10-bottom">
                                    How many days after this customers billing period date starts would you like to make their bill for pool service due?
                                </div>
                                <div className="form-group no-margin col-sm-4 no-padding">
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={this.state.days_til_due_date || ""}
                                        onChange={(e) => this.props.updateMasterState({ days_til_due_date: e.target.value })}
                                    />
                                </div>
                                <ValidationMessage condition={this.state.validations.days_til_due_date} message="Must set how many days customer has to pay bill before it will become due" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
