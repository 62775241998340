import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Utils from 'Utils';
import { toast } from 'react-toastify';

import ServiceItem from './ServiceItem';

export default class BillableItems extends React.Component {
    static propTypes = {
        account_id: PropTypes.number.isRequired,
        billable_service_items: PropTypes.array.isRequired,
        billable_repairs: PropTypes.array.isRequired,
        base_plan: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            serviceItems: props.billable_service_items,
            repairs: props.billable_repairs
        }

        this.state = this.baseState
    }

    componentDidMount() {
        if ($("a#billable-items-show").length > 0) {
            $("a#billable-items-show").on("click", (e) => {
                e.preventDefault();

                $("#account-billable-items-modal").modal("show");
            });

            $("#account-billable-items-modal").on("show.bs.modal", (e) => {
                $(e.target).addClass("center-screen-modal");
            });

            $("#account-billable-items-modal").on("hide.bs.modal", (e) => {
                $(e.target).removeClass("center-screen-modal");
            });
        }
    }

    renderServiceItems = () => {
        return this.state.serviceItems.map((serviceItem, index) => {
            return(
                <ServiceItem
                    key={index}
                    index={index}
                    serviceItem={serviceItem}
                    destroyServiceItem={this.destroyServiceItem}
                    updateServiceItem={this.updateServiceItem}
                    accountId={this.props.account_id}
                />
            )
        });
    };

    renderRepairs = () => {
        return this.state.repairs.map((repair, index) => {
            const linkUrl = `/accounts/${repair.account_id}/repairs/${repair.id}`;

            return(
                <li className="list-group-item" key={index}>
                    <div className="display-flex">
                        <div className="flex-2 display-flex align-items-center margin-10-right">
                            <a className="padding-20-right" href={linkUrl}>{ Utils.truncate(repair.description, 75) }</a>
                        </div>
                        <div className="flex-1 display-flex align-items-center margin-10-right">
                            { Utils.floatToCurrency(repair.grand_total) }
                        </div>
                        <div className="flex-1 display-flex align-items-center margin-10-right">
                            { Utils.humanize(repair.status) }
                        </div>
                        <div className="flex-1 display-flex align-items-center" onClick={() => this.markRepairNotBillable(repair)}>
                            <button className="btn btn-danger">
                                <span className="glyphicon glyphicon-trash"></span>
                            </button>
                        </div>
                    </div>
                </li>
            )
        });
    };

    destroyServiceItem = (serviceItem, index) => {
        if (!serviceItem.id) {
            const serviceItems = this.state.serviceItems.filter((si, _index) => _index !== index);

            this.setState({ serviceItems });
        } else {
            if (confirm("Are you sure you want to destroy this service item? This cannot be undone.")) {
                $.ajax({
                    method: "DELETE",
                    url: `/service_items/${serviceItem.id}.json`
                }).done(() => {
                    const serviceItems = this.state.serviceItems.filter((si, _index) => _index !== index);

                    this.setState({ serviceItems });

                    toast.success("Successfully Destroyed Service Item!", {
                        position: toast.POSITION.TOP_CENTER
                    })
                }).fail(() => {
                    toast.error("Unable to Destroy Service Item... Please try again later", {
                        position: toast.POSITION.TOP_CENTER
                    })
                });
            }
        }
    };

    markRepairNotBillable = (repair) => {
        if (confirm("This repair will be marked as not billable, this will NOT destroy this repair. Would you like to continue?")) {
            $.ajax({
                method: "PUT",
                url: `/accounts/${repair.account_id}/repairs/${repair.id}.json`,
                data: {
                    repair: {
                        billable: false
                    }
                }
            }).done(() => {
                const repairs = this.state.repairs.filter(repair => repair.id !== repair.id);

                this.setState({ repairs });

                toast.success("Successfully unmarked Repair as billable!", {
                    position: toast.POSITION.TOP_CENTER
                });
            }).fail(() => {
                toast.error("Unable to update Repair... Please try again later", {
                    position: toast.POSITION.TOP_CENTER
                })
            });
        }
    };

    addServiceItem = (e) => {
        e.preventDefault();

        this.setState({ serviceItems: [...this.state.serviceItems, {}] });
    };

    updateServiceItem = (serviceItem, index) => {
        const serviceItems = [...this.state.serviceItems].map((si, _index) => {
            if (_index === index) return serviceItem;
            return si;
        });

        this.setState({ serviceItems });
    };

    render() {
        return(
            <div
                id="account-billable-items-modal"
                className="modal fade"
                tabIndex="-1"
                role="dialog"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title">
                                <h5 className="modal-title float-left">
                                    Billable Items
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <Fragment>
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">
                                            Service Items
                                        </h3>
                                    </div>
                                    <div className="panel-body">
                                        { this.state.serviceItems.length > 0 &&
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <td>
                                                                <h5>
                                                                    Name:
                                                                </h5>
                                                            </td>
                                                            <td>
                                                                <h5>
                                                                    Price:
                                                                </h5>
                                                            </td>
                                                            <td>
                                                                <h5>
                                                                    Qty:
                                                                </h5>
                                                            </td>
                                                            <td>
                                                                <h5>
                                                                    Recurring:
                                                                </h5>
                                                            </td>
                                                            <td>
                                                                <h5>
                                                                    Actions:
                                                                </h5>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { this.renderServiceItems() }
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                        { this.state.serviceItems.length === 0 &&
                                            <div className="display-flex justify-content-center align-items-center">
                                                <h4>
                                                    No billable service items
                                                </h4>
                                            </div>
                                        }

                                        <hr/>

                                        <div className="text-center">
                                            <a href="#" onClick={this.addServiceItem}>
                                                + Add Billable Service Item
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">
                                            Repairs
                                        </h3>
                                    </div>
                                    <div className="panel-body">
                                        { this.state.repairs.length > 0 &&
                                            <div>
                                                <div className="display-flex padding-15-left padding-15-right margin-10-bottom">
                                                    <div className="flex-2 margin-10-right">
                                                        <h5>
                                                            Desription:
                                                        </h5>
                                                    </div>
                                                    <div className="flex-1 margin-10-right">
                                                        <h5>
                                                            Grand Total:
                                                        </h5>
                                                    </div>
                                                    <div className="flex-2 margin-10-right">
                                                        <h5>
                                                            Status:
                                                        </h5>
                                                    </div>
                                                </div>
                                                <ul className="list-group">
                                                    { this.renderRepairs() }
                                                </ul>
                                            </div>
                                        }
                                        { this.state.repairs.length === 0 &&
                                            <div className="display-flex justify-content-center align-items-center">
                                                <h4>
                                                    No billable repairs
                                                </h4>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Fragment>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
