import React, { Fragment, useContext } from 'react';
import { ShowRepairContext } from '../ShowRepair';

import { toast } from 'react-toastify'

import RepairItem from './Item';

const RepairItems = () => {
    const context = useContext(ShowRepairContext);
    const repair = context.repair;

    const addRepairItem = (e) => {
        e.preventDefault();

        if (context.repair.account.active) {
            context.updateRepair({ ...repair, repair_items: [...repair.repair_items, {}] })
        } else {
            toast.error("Account must be active in order to add a Repair Item", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    };

    const viewableRepairItems = () => {
        if (context.isTech()) {
            return context.repair.repair_items.filter(r => r.appointment && r.appointment.user_id === context.currentUser.id);
        } else {
            return context.repair.repair_items;
        }
    };

    return(
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title">
                    Repair Items:
                </h3>
            </div>
            <div className="panel-body">
                { viewableRepairItems().length > 0 &&
                    <Fragment>
                        {
                            viewableRepairItems().map((ri, index) => (
                                <RepairItem
                                    key={index}
                                    repairItem={ri}
                                    index={index}
                                />
                            ))
                        }
                    </Fragment>
                }
                { !context.currentUser.roles.includes("repair_tech") &&
                    <div className="text-center">
                        <a href="#" onClick={addRepairItem}>
                            + Add Repair Item
                        </a>
                    </div>
                }
            </div>
        </div>
    )
};

export default RepairItems;
