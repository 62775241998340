import ReactOnRails from 'react-on-rails';
import ToastContainer from '@shared/components/ToastContainer';

import ServiceStopShow from './Main/components/ServiceStops/Show';

import ReportsRoot from './Main/components/Reports/ReportsRoot';

import SettingsButtons from './Main/components/Settings/SettingsButtons';
import UsersSettings from './Main/components/Settings/UsersSettings/Settings';
import AddInvoiceIntegrations from './Main/components/Settings/AddInvoiceIntegrations';
import CustomerFeedbackSettings from '@main/components/Settings/CustomerFeedback';
import MasterServiceItemsGroup from './Main/components/Settings/MasterServiceItemsGroup';
import PreviewServiceStopMailer from './Main/components/Settings/ServiceStops/PreviewServiceStopMailer';
import ServiceStopTasksSettings from './Main/components/Settings/ServiceStops/Tasks';
import FallbackSettingsCheckbox from './Main/components/Settings/FallbackCheckbox';
import TextMessageSettings from './Main/components/Settings/TextMessageSettings';
import SubscriptionSettings from './Main/components/Settings/SubscriptionSettings';
import CompanySettingsAddress from './Main/components/Settings/Company/Address';

import InvoiceIndex from './Main/components/Invoices/Index';
import InvoiceShow from './Main/components/Invoices/Show/Index';

import ImageArea from '@shared/components/ImageArea/ImageArea';

import TextMessagesConversationsModal from './Main/components/TextMessages/ConversationsModal';

import Calendar from './Main/components/Appointments/Calendar';

import Dashboard from './Main/components/Dashboard/Index';

import MatchExternalCustomers from './Main/components/Accounts/ExternalMatch/Provider';
import AccountBillableItems from './Main/components/Accounts/BillableItems/Index';
import AccountEmailNotifications from '@main/components/Accounts/Settings/Notifications/Index';
import PdfModal from '@shared/components/Invoices/PdfModal';
import AccountFormWizard from './Main/components/Accounts/AccountForm/Wizard';
import ContactInfos from '@main/components/Accounts/ContactInfos/Index'

import ScheduleRouteReminderModal from './Main/components/RouteReminders/ScheduledModal';
import ManageCreditCards from './Main/components/Accounts/ManageCreditCards';
import AccountServiceStops from './Main/components/Accounts/ServiceStops/Index';
import AccountServiceStopForm from './Main/components/Accounts/ServiceStops/Form';

import RepairsIndex from './Main/components/Repairs/Index';
import RepairForm from './Main/components/Repairs/RepairForm';
import ShowRepair from './Main/components/Repairs/ShowRepair';
import ScheduleRepair from './Main/components/Repairs/ScheduleRepair';

import OnboardingSignUpForm from './Main/components/Onboarding/SignUpForm';

import AdminDashboard from './Main/components/Admin/Dashboard';

import TypedHeader from './Main/components/Landing/TypedHeader';

import ActivateSubscription from './Main/components/Subscription/Activate';
import SubscriptionPlans from './Main/components/Subscription/SubscriptionPlans';
import SubscriptionPricing from './Main/components/Subscription/SubscriptionPricing';

import LockedFeature from './Main/components/Subscription/LockedFeature';

ReactOnRails.register({
  ToastContainer,
  AccountServiceStopForm,
  AccountServiceStops,
  ServiceStopShow,
  ReportsRoot,
  SettingsButtons,
  UsersSettings,
  CompanySettingsAddress,
  MasterServiceItemsGroup,
  AddInvoiceIntegrations,
  CustomerFeedbackSettings,
  PreviewServiceStopMailer,
  ServiceStopTasksSettings,
  FallbackSettingsCheckbox,
  TextMessageSettings,
  SubscriptionSettings,
  InvoiceIndex,
  InvoiceShow,
  ImageArea,
  TextMessagesConversationsModal,
  Calendar,
  Dashboard,
  MatchExternalCustomers,
  AccountFormWizard,
  AccountBillableItems,
  AccountEmailNotifications,
  ContactInfos,
  PdfModal,
  ScheduleRouteReminderModal,
  ManageCreditCards,
  RepairsIndex,
  RepairForm,
  ShowRepair,
  ScheduleRepair,
  OnboardingSignUpForm,
  AdminDashboard,
  TypedHeader,
  SubscriptionPlans,
  SubscriptionPricing,
  ActivateSubscription,
  LockedFeature
});

if(navigator.userAgent.match(/iPhone/)) {
    $('html').addClass('iphone');
}
