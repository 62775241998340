import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import SubscriptionPricing from './SubscriptionPricing';

import SubUtils from './SubUtils';
import Utils from '../../Utils';

export default class Activate extends React.Component {
    static propTypes = {
        company: PropTypes.object.isRequired,
        stripe_key: PropTypes.string.isRequired,
        active_accounts: PropTypes.number.isRequired,
        grandfathered: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.baseState = {
            selectedAccounts: props.company.subscription ? SubUtils.accountLimit(props.company.subscription, this.props.grandfathered) : window.localStorage.getItem("selectedAccounts") || "50",
            activePlan: "pro",
            stage: "setPlan",
            loading: false,
            takeCard: null,
            cardValid: null,
            cardError: null,
            validatingCard: null,
            cardToken: null
        }

        this.state = this.baseState;
    }

    subscriptionAction = (activePlan, selectedAccounts) => {
        let stage = "confirmSub";

        this.setState({ activePlan, selectedAccounts, stage }, () => {
            if (!this.props.company.subscription) {
                this.setCard();
            }
        });
    };

    updateActivateTxt = () => {
        if (!this.props.company.subscription) {
            return "Start Risk-Free 30 Day Trial"
        } else if (SubUtils.activeSubscription(this.props.company.subscription)) {
            return "Update Subscription";
        } else {
            return "Activate Subscription";
        }
    };

    monthlyPrice = () => {
        if (this.props.grandfathered) {
            if (this.state.selectedAccounts <= 120) {
                return 120
            } else {
                const diff = (this.state.selectedAccounts - 120) * 0.75
                return diff + 120
            }
        } else {
            return this.state.selectedAccounts
        }
    }

    activateSubscription = () => {
        this.setState({ loading: true });

        const method = this.props.company.subscription ? "PUT" : "POST";

        const url = "/subscriptions.json";

        $.ajax({
            url,
            method,
            data: {
                company_id: this.props.company.id,
                plan: this.state.activePlan,
                accounts: this.state.selectedAccounts,
                card_token: this.state.cardToken
            }
        }).done((res) => {
            window.localStorage.removeItem("selectedAccounts");
            window.localStorage.removeItem("activePlan");
            window.location = "/accounts"
        }).fail((res) => {
            this.setState({ loading: false, validatingCard: false, cardValid: false, cardError: res.responseJSON.error }, () => {
                this.setCard();
            });
        });
    };

    updateSubscription = () => {
        if (SubUtils.activeSubscription(this.props.company.subscription)) {
            this.activateSubscription()
        } else {
            this.setCard();
        }
    };

    setCard = () => {
        this.setState({ takeCard: true }, () => {
            const stripe = Stripe(this.props.stripe_key)
            const elements = stripe.elements();
            const card = elements.create("card");

            this.stripe = stripe;
            this.stripeCard = card;

            card.mount("#card-element");
            card.on("change", (e) => {
                if (e.complete) {
                    this.setState({ cardValid: true });
                } else {
                    this.setState({ cardValid: false });
                }
            });
        });
    };

    validateCard = () => {
        this.setState({ validatingCard: true });

        this.stripe.createToken(this.stripeCard).then(result => {
            if (result.error) {
                this.setState({ cardError: result.error.message, validatingCard: false });
            } else {
                this.setState({ cardToken: result.token }, () => {
                    this.activateSubscription();
                });
            }
        });
    };

    minAmount = () => {
        if (this.props.grandfathered) {
            if (this.props.active_accounts < 120) {
                return 120
            } else {
                return this.props.active_accounts
            }
        } else {
            return this.props.active_accounts
        }
    }

    render() {
        return(
            <div className="activate-subscription-wrapper display-flex flex-column justify-content-center height-100">
                <div className="row margin-20-top margin-20-bottom">
                    <div className="col-md-8 col-md-offset-2 col-xs-10 col-xs-offset-1">
                        <div className="row">
                            <img
                                className="logo"
                                src="https://s3.amazonaws.com/superswimbros.dev/simple-logo.png"
                                alt="AquaSuite Pool Service Management Software"
                            />
                        </div>
                        { this.props.company.subscription && !SubUtils.activeSubscription(this.props.company.subscription) &&
                            <div className="alert alert-danger text-center">
                                Your AquaSuite Subscription has expired, renew it now to continue
                            </div>
                        }
                        { this.props.company.subscription && SubUtils.activeSubscription(this.props.company.subscription) &&  this.props.active_accounts >= SubUtils.accountLimit(this.props.company.subscription) &&
                            <div className="alert alert-warning text-center">
                                You have reached your subscription's limit of active accounts, upgrade your subscription to continue
                            </div>
                        }

                        <div className="row">
                            <div className="panel panel-primary">
                                <div className="panel-heading">
                                    <h3 className="panel-title">
                                        { this.updateActivateTxt() }
                                    </h3>
                                </div>
                                <div className="panel-body">
                                    { !this.state.loading && this.state.stage === "setPlan" &&
                                        <div className="margin-20-top margin-20-bottom">
                                            <SubscriptionPricing
                                                buttonTxt={this.updateActivateTxt()}
                                                callback={this.subscriptionAction}
                                                activePlan={this.state.activePlan}
                                                selectedAccounts={this.state.selectedAccounts.toString()}
                                                subscription={this.props.company.subscription}
                                                minAmount={this.minAmount()}
                                                grandfathered={this.props.grandfathered}
                                            />
                                        </div>
                                    }
                                    { !this.state.loading && this.state.stage === "confirmSub" &&
                                        <div className="row margin-20-top">
                                            <div className="col-md-12">
                                                <div className="panel panel-default height-100">
                                                    <div className="panel-body display-flex flex-column justify-content-center">
                                                        <div className="display-flex flex-column  justify-content-center align-items-center">
                                                            <div className="display-flex justify-content-center">
                                                                <img
                                                                    className="plan-logo"
                                                                    src="https://s3.amazonaws.com/superswimbros.dev/no+word+smaller+logo.png"
                                                                    alt="AquaSuite Pool Service Management Software"/>
                                                                <h4 className="display-inline-block">
                                                                    <span className="label label-warning">
                                                                        { Utils.humanize(this.state.activePlan) }
                                                                    </span>
                                                                </h4>
                                                            </div>
                                                            <h4>
                                                                { this.state.selectedAccounts } Accounts
                                                            </h4>
                                                            <div>
                                                                Price:
                                                            </div>
                                                            <h4 className="margin-20-bottom">
                                                                ${ this.monthlyPrice() }/month
                                                            </h4>
                                                        </div>

                                                        { !this.state.takeCard &&
                                                            <Fragment>
                                                                { this.props.company.subscription &&
                                                                    <button className="btn btn-success btn-lg" onClick={(e) => this.updateSubscription()}>
                                                                        { this.updateActivateTxt() }
                                                                    </button>
                                                                }
                                                            </Fragment>
                                                        }

                                                        { this.state.takeCard &&
                                                            <Fragment>
                                                                { this.state.cardError &&
                                                                    <div className="alert alert-warning">
                                                                        { this.state.cardError }
                                                                    </div>
                                                                }

                                                                <div className={`margin-10-bottom ${this.state.validatingCard ? "display-none" : "display-block"}`}>
                                                                    <label>Credit or Debit Card:</label>
                                                                    <div id="card-element">
                                                                    </div>
                                                                </div>

                                                                { !this.props.company.subscription &&
                                                                    <h5 className="text-center margin-20-bottom">
                                                                        Your card will be charged { this.monthlyPrice() } every month, starting today
                                                                    </h5>
                                                                }

                                                                { !this.state.validatingCard &&
                                                                    <button
                                                                        className="btn btn-success btn-lg"
                                                                        disabled={!this.state.cardValid}
                                                                        onClick={(e) => this.validateCard()}
                                                                    >
                                                                        { SubUtils.activeSubscription(this.props.company.subscription) ? "Upgrade Subscription" : "Activate Subscription" }
                                                                    </button>
                                                                }
                                                                { this.state.validatingCard &&
                                                                    <h5 className="text-center">
                                                                        Validating Card...
                                                                    </h5>
                                                                }
                                                            </Fragment>
                                                        }
                                                        <button onClick={() => this.setState({ stage: "setPlan", takeCard: false })} className="btn btn-primary margin-10-top">
                                                            Adjust Accounts
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    { this.state.loading &&
                                        <div className="display-flex flex-column justify-content-center align-items-center height-100 margin-20-bottom margin-20-top">
                                            <h3 className="no-margin-top">
                                                Please wait while we { this.props.company.subscription ? "update" : "activate" } your subscription
                                            </h3>
                                            <div>
                                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw align-self-center flex-1"></i>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
