import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import RepairForm from './RepairForm';
import ScheduleRepair from './ScheduleRepair';
import SaveTemplate from './SaveTemplate';
import RepairInvoice from './RepairInvoice';
import RepairExpenses from './Expenses/Index';
import RepairItems from './Items/Index';
import ImageArea from '@shared/components/ImageArea/ImageArea';
import RepairAttachmentsList from './Attachments/List';
import SendAttachmentModal from './Attachments/SendModal';
import SettingsModal from './Settings/Modal';
import AccountDetails from './AccountDetails';

import Utils from '../../Utils';
import RepairUtils from './Utils';
import { toast } from 'react-toastify';

export const ShowRepairContext = React.createContext();

export default class ShowRepair extends React.Component {
    static propTypes = {
        repair: PropTypes.object.isRequired,
        repair_items: PropTypes.array.isRequired,
        techs: PropTypes.array.isRequired,
        is_not_admin: PropTypes.bool.isRequired,
        current_user: PropTypes.object.isRequired,
        timezone: PropTypes.string.isRequired,
        statuses: PropTypes.array.isRequired,
        base_plan: PropTypes.string.isRequired,
        company: PropTypes.object.isRequired,
        rich_media_templates: PropTypes.array.isRequired,
        repair_item_templates: PropTypes.array.isRequired,
        can_view_grand_total: PropTypes.bool.isRequired,
        repair_tags: PropTypes.array.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            repair: { ...props.repair, repair_items: [...props.repair_items] },
            editing: false,
            invoiceAction: null,
            repairItemTemplates: props.repair_item_templates,
            repairTags: props.repair_tags
        };

        this.priorityLabelClasses = {
            low: "default",
            medium: "primary",
            high: "warning",
            critical: "danger"
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        if (window.location.hash == "#success") {
            toast.success("Repair successfully saved!", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    showTemplateModal = () => {
        $("#save-repair-template-modal").modal("show");
    };

    saveRepairCallback = (repair) => {
        this.setState({ repair, repairTags: repair.repair_tags, editing: false });
    };

    setAppointment = (appointment) => {
        this.setState({ repair: { ...this.state.repair, appointment } })
    };

    updateImagesCallback = (images) => {
        this.setState({ repair: { ...this.state.repair, images } } );
    };

    updateExpenseSetCallback = (expense_set) => {
        this.setState({ repair: { ...this.state.repair, expense_set } });
    };

    updateRepair = (repair) => {
        this.setState({ repair });
    };

    updateRepairItem = (repairItem, index) => {
        const repair_items = this.state.repair.repair_items.map((ri, _index) => {
            if (index === _index) {
                return { ...repairItem }
            } else {
                return { ...ri }
            }
        });

        this.setState({ repair: { ...this.state.repair, repair_items } });
    };

    addRepairItemTemplate = (template) => {
        this.setState({ repairItemTemplates: [...this.state.repairItemTemplates, template] })
    };

    toggleEditing = () => {
        this.setState({ editing: !this.state.editing });
    };

    getAttachments = () => {
        return this.repairAttachmentList.getAttachments();
    };

    canViewCommission = () => {
        return (this.props.current_user.id === this.state.repair.user_id) || !this.props.is_not_admin
    };

    belongsToCurrentUser = () => {
        return this.state.repair.appointment && this.state.repair.appointment.user_id === this.props.current_user.id
    }

    canViewImages = () => {
        if (!this.props.is_not_admin) {
            return true;
        } else {
            if (this.state.repair.images.length > 0 || this.belongsToCurrentUser()) {
                return true
            }
        }

        return false;
    };

    canAddImages = () => {
        if (!this.props.is_not_admin) {
            return true;
        } else if (this.state.repair.appointment && this.state.repair.appointment.user_id === this.props.current_user.id) {
            return true;
        } else {
            return false;
        }
    };

    canUseRepairItems = () => {
        return (this.props.base_plan === "pro" && !this.state.repair.appointment)
    };

    repairItemGrandTotal = () => {
        const subTotal = RepairUtils.repairItemGrandTotal(this.state.repair);
        let grandTotal

        if (parseFloat(this.state.repair.discount) > 0) {
            const discountAmount = this.state.repair.discount * subTotal;
            grandTotal = subTotal - discountAmount
        } else {
            grandTotal = subTotal;
        }

        return parseFloat(grandTotal);
    };

    repairDiscount = () => {
        if (parseFloat(this.state.repair.discount) > 0) {
            return(
                <span>
                    { `(${Utils.floatToPercentage(parseFloat(this.state.repair.discount))} discount applied)` }
                </span>
            );
        }
    };

    findRepairTagColor = (tag) => {
        const masterTag = this.state.repairTags.find(t => t.name === tag);
        if (masterTag) return masterTag.color;
    };

    showSettingsModal = () => {
        this.settingsModalEl.showModal();
    };

    isTech = () => {
        return this.props.current_user.roles.includes("repair_tech");
    };

    showAttachmentsModal = (e) => {
        e.preventDefault();

        if (this.props.repair.account.active) {
            $("#send-repair-attachments-modal").modal("show");
        } else {
            toast.error("Account must be active to send attachment(s)", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    editRepair = (e) => {
        if (this.props.repair.account.active) {
            this.setState({ editing: true })
        } else {
            toast.error("Account must be active to edit Repair", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    render() {
        return(
            <ShowRepairContext.Provider
                value={{
                    repair: this.state.repair,
                    updateRepair: this.updateRepair,
                    updateRepairItem: this.updateRepairItem,
                    techs: this.props.techs,
                    currentCompany: this.props.company,
                    currentUser: this.props.current_user,
                    isTech: this.isTech,
                    repairItemTemplates: this.state.repairItemTemplates,
                    addRepairItemTemplate: this.addRepairItemTemplate
                }}
            >
                <div className="repair-show-wrapper">
                    <div className="row no-margin-top">
                        <div className="col-md-12">
                            <a className="btn btn-default" href="/repairs">
                                <span className="glyphicon glyphicon-wrench"></span>
                                &nbsp;
                                Back to Repairs
                            </a>
                        </div>
                    </div>
                    <div className="row page-header no-margin-top">
                        <div className="col-md-12">
                            <h1>
                                Repair Details
                                <small>
                                    &nbsp;- <a href={`/accounts/${this.state.repair.account.id}`}>
                                        { this.state.repair.account.full_location }
                                    </a>
                                </small>
                            </h1>
                        </div>
                    </div>
                    { this.state.editing &&
                        <RepairForm
                            repair={this.state.repair}
                            statuses={this.props.statuses}
                            techs={this.props.techs}
                            timezone={this.props.timezone}
                            saveCallback={this.saveRepairCallback}
                            toggleEditing={this.toggleEditing}
                            setAppointment={this.setAppointment}
                            updateImagesCallback={this.updateImagesCallback}
                            base_plan={this.props.base_plan}
                            current_user={this.props.current_user}
                            is_not_admin={this.props.is_not_admin}
                            can_view_grand_total={this.props.can_view_grand_total}
                            repair_tags={this.state.repairTags}
                        />
                    }
                    { !this.state.editing &&
                        <div>
                            { (!this.props.is_not_admin || this.belongsToCurrentUser()) &&
                                <div className="row margin-20-bottom">
                                    <div className="col-md-8">
                                        <div className="dropdown pull-right">
                                            <button
                                                className="btn btn-lg btn-success dropdown-toggle actions-dropdown"
                                                type="button"
                                                data-toggle="dropdown"
                                            >
                                                Actions
                                                <span className="glyphicon glyphicon-chevron-down"></span>
                                            </button>
                                            <ul className="dropdown-menu">
                                                { !this.props.is_not_admin &&
                                                    <li>
                                                        <a
                                                            href="#"
                                                            className="text-center padding-10"
                                                            onClick={(e) => { e.preventDefault(); this.showTemplateModal() }}
                                                        >
                                                            <i className="fa fa-save"></i>
                                                            &nbsp;
                                                            Save as Template
                                                        </a>
                                                    </li>
                                                }
                                                <li>
                                                    <a
                                                        href="#"
                                                        className="text-center padding-10"
                                                        onClick={this.editRepair}
                                                    >
                                                        <span className="glyphicon glyphicon-pencil"></span>
                                                        &nbsp;
                                                        Edit Repair
                                                    </a>
                                                </li>
                                                { !this.props.is_not_admin &&
                                                    <li>
                                                        <a
                                                            href="#"
                                                            className="text-center padding-10"
                                                            onClick={this.showAttachmentsModal}
                                                        >
                                                            <i className="fa fa-paperclip"></i>
                                                            &nbsp;
                                                            Send Attachment(s)
                                                        </a>
                                                    </li>
                                                }
                                                { !this.props.is_not_admin &&
                                                    <li>
                                                        <a
                                                            href="#"
                                                            className="text-center padding-10"
                                                            onClick={(e) => { e.preventDefault(); this.showSettingsModal() }}
                                                        >
                                                            <i className="fa fa-cog"></i>
                                                            &nbsp;
                                                            Settings
                                                        </a>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }

                            <AccountDetails account={this.state.repair.account} />

                            <div className="row">
                                <div className="col-md-8">
                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">
                                                Client:
                                            </h3>
                                        </div>
                                        <div className="panel-body">
                                            <a href={`/accounts/${this.state.repair.account_id}`}>
                                                { this.state.repair.account.contact_name }
                                            </a>
                                        </div>
                                    </div>

                                    { this.state.repair.estimate_number &&
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h3 className="panel-title">
                                                    Estimate Number:
                                                </h3>
                                            </div>
                                            <div className="panel-body">
                                                { Utils.pad(this.state.repair.estimate_number, 5) }
                                            </div>
                                        </div>
                                    }

                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">
                                                Status:
                                            </h3>
                                        </div>
                                        <div className="panel-body">
                                            { Utils.humanize(this.state.repair.status) }
                                        </div>
                                    </div>

                                    { this.state.repair.status === "denied" &&
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h3 className="panel-title">
                                                    Reason for Denial:
                                                </h3>
                                            </div>
                                            <div className="panel-body">
                                                { this.state.repair.denial_reason || "--" }
                                            </div>
                                        </div>
                                    }

                                    <div className="row">
                                        <div className={`${this.state.repair.tags ? "col-xs-6" : "col-md-12" }`}>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <h3 className="panel-title">
                                                        Priority:
                                                    </h3>
                                                </div>
                                                <div className="panel-body">
                                                    { this.state.repair.priority &&
                                                        <span className={`label label-${this.priorityLabelClasses[this.state.repair.priority]}`}>
                                                            { Utils.humanize(this.state.repair.priority) }
                                                        </span>
                                                    }
                                                    { !this.state.repair.priority &&
                                                        "--"
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        { this.state.repair.tags &&
                                            <div className="col-xs-6">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading">
                                                        <h3 className="panel-title">
                                                            { this.state.repair.tags.split(",").length > 0 ? "Tags:" : "Tag:" }
                                                        </h3>
                                                    </div>
                                                    <div className="panel-body tag-wrapper">
                                                        {
                                                            this.state.repair.tags.split(",").map((tag, index) => {
                                                                return(
                                                                    <span
                                                                        key={index}
                                                                        className={`label margin-5-right`}
                                                                        style={{ background: this.findRepairTagColor(tag) }}
                                                                    >
                                                                        { tag }
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    { !this.props.is_not_admin &&
                                        <Fragment>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <h3 className="panel-title">
                                                        Invoice:
                                                    </h3>
                                                </div>
                                                <div className="panel-body">
                                                    <RepairInvoice
                                                        accountActive={this.props.repair.account.active}
                                                        repair={this.state.repair}
                                                        timezone={this.props.timezone}
                                                        callback={(billable) => this.setState({ repair: { ...this.state.repair, billable } })}
                                                    />
                                                </div>
                                            </div>
                                        </Fragment>
                                    }

                                    { this.state.repair.repair_items.length < 1 &&
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h3 className="panel-title">
                                                    Appointment:
                                                </h3>
                                            </div>
                                            <div className="panel-body">
                                                <div className="display-flex justify-content-center">
                                                    <ScheduleRepair
                                                        appointment={this.state.repair.appointment}
                                                        repair={this.props.repair}
                                                        techs={this.props.techs}
                                                        timezone={this.props.timezone}
                                                        account={this.state.repair.account}
                                                        appointmentCallback={this.setAppointment}
                                                        isNotAdmin={this.props.is_not_admin}
                                                        currentUser={this.props.current_user}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">
                                                Description:
                                            </h3>
                                        </div>
                                        <div className="panel-body">
                                            { this.state.repair.description }
                                        </div>
                                    </div>

                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">
                                                Public Notes:
                                            </h3>
                                        </div>
                                        <div className="panel-body">
                                            { this.state.repair.client_notes }
                                            { !this.state.repair.client_notes &&
                                                <h5 className="text-center">
                                                    { this.state.repair.repair_items.length > 0 ?
                                                        "No Public Notes set"
                                                    :
                                                        "No Public Notes set - Description will be used instead"
                                                    }
                                                </h5>
                                            }
                                        </div>
                                    </div>

                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">
                                                Private Notes:
                                            </h3>
                                        </div>
                                        <div className="panel-body">
                                            { this.state.repair.private_notes }
                                            { !this.state.repair.private_notes &&
                                                <h5 className="text-center">
                                                    No Private Notes set
                                                </h5>
                                            }
                                        </div>
                                    </div>

                                    { this.canUseRepairItems() &&
                                        <RepairItems />
                                    }
                                    
                                    <RepairExpenses
                                        expenseSet={this.state.repair.expense_set}
                                        repair={this.state.repair}
                                        updateCallback={this.updateExpenseSetCallback}
                                    />

                                    { this.state.repair.user_id && this.canViewCommission() &&
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h3 className="panel-title">
                                                    Commission Payable To:
                                                </h3>
                                            </div>
                                            <div className="panel-body">
                                                { this.state.repair.user ? this.state.repair.user.name : "" }
                                            </div>
                                        </div>
                                    }

                                    { this.state.repair.service_tech_commission_amount && this.canViewCommission() &&
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h3 className="panel-title">
                                                    Commission Amount:
                                                </h3>
                                            </div>
                                            <div className="panel-body">
                                                { this.state.repair.service_tech_commission_amount ? Utils.floatToCurrency(parseFloat(this.state.repair.service_tech_commission_amount)) : "" }
                                            </div>
                                        </div>
                                    }

                                    { this.canViewImages() &&
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h3 className="panel-title">
                                                    Images:
                                                </h3>
                                            </div>
                                            <div className="panel-body">
                                                <ImageArea
                                                    images={this.state.repair.images}
                                                    resource_name="repair"
                                                    resource_id={this.state.repair.id}
                                                    multiple_allowed={this.canAddImages()}
                                                    updateImagesCallback={this.updateImagesCallback}
                                                />
                                            </div>
                                        </div>
                                    }

                                    { this.props.can_view_grand_total &&
                                        <div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <h3 className="panel-title">
                                                        Total Repair Cost:
                                                    </h3>
                                                </div>
                                                <div className="panel-body">
                                                    { this.repairItemGrandTotal() > 0 ?
                                                        <div>
                                                            { this.repairItemGrandTotal() > 0 ? Utils.floatToCurrency(this.repairItemGrandTotal()) : "--" }
                                                            &nbsp;
                                                            { this.repairDiscount() }
                                                        </div>
                                                    :
                                                        <Fragment>
                                                            { parseFloat(this.state.repair.grand_total) > 0.0 &&
                                                                <div>
                                                                    { Utils.floatToCurrency(this.state.repair.grand_total) }
                                                                    &nbsp;
                                                                    { this.repairDiscount() }
                                                                </div>
                                                            }
                                                            { parseFloat(this.state.repair.grand_total) === 0.0 &&
                                                                <h5 className="text-center">
                                                                    No cost set
                                                                </h5>
                                                            }
                                                        </Fragment>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">
                                                Terms/Conditions:
                                            </h3>
                                        </div>
                                        <div className="panel-body">
                                            { this.state.repair.terms }
                                            { !this.state.repair.terms &&
                                                <h5 className="text-center">
                                                    No terms/conditions set
                                                </h5>
                                            }
                                        </div>
                                    </div>

                                    { !this.props.is_not_admin &&
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h3 className="panel-title">
                                                    Attachments:
                                                </h3>
                                            </div>
                                            <div className="panel-body">
                                                <RepairAttachmentsList
                                                    richMediaElements={this.state.repair.rich_media_elements}
                                                    templates={this.props.rich_media_templates}
                                                    repair={this.state.repair}
                                                    company={this.props.company}
                                                    basePlan={this.props.base_plan}
                                                    ref={(e) => this.repairAttachmentList = e}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <SaveTemplate repair={this.state.repair} getAttachments={this.getAttachments} />
                            <SendAttachmentModal repair={this.state.repair} basePlan={this.props.base_plan} />
                        </div>
                    }
                    <SettingsModal
                        company={this.props.company}
                        repair={this.state.repair}
                        updateRepairSettings={(settings) => this.updateRepair({ ...this.state.repair, settings })}
                        basePlan={this.props.base_plan}
                        ref={(e) => this.settingsModalEl = e}
                    />
                </div>
            </ShowRepairContext.Provider>
        )
    }
}
