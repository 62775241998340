import React from 'react';
import PropTypes from 'prop-types';

import { WizardConsumer } from '../Wizard';

import ValidationMessage from '../ValidationMessage';
import SuggestedTech from '../../../Appointments/SuggestedTech';

export default class Appointment extends React.Component {
    static propTypes = {
        _key: PropTypes.number.isRequired,
        appointment: PropTypes.object.isRequired,
        timezone: PropTypes.string.isRequired,
        serviceTechs: PropTypes.array.isRequired,
        updateAppointment: PropTypes.func.isRequired,
        removeAppointment: PropTypes.func.isRequired,
        hasSuggestion: PropTypes.bool.isRequired
    }

    constructor(props) {
        super(props);

        this.baseState = {
            ...props,
            start: props.start ? moment.tz(props.start, props.timezone).format("YYYY-MM-DD") : "",
            type: "service_stop",
            ...props.appointment,
            notification: null,
            suggestedScheduling: null,
            validations: {
                start: false,
                user_id: false
            }
        }

        this.state = this.baseState;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentWillReceiveProps(props) {
        // wrap in timeout so checkInvalid will setState and this won't override it
        setTimeout(() => {
            // the validation check in Step 2 can get called when the component is unmounted
            if (this.mounted) {
                this.setState({ ...this.state, ...props.appointment });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const results = ["id", "start", "end", "user_id", "notification"].map(prop => {
            return prevState[prop] === this.state[prop];
        });

        if (results.indexOf(false) > -1) {
            this.props.updateAppointment(this.state._key);
        }
    }

    getAppointment() {
        let { id, start, end, set_times, user_id, type, notification, _destroy, suggestedScheduling } = this.state;

        return { id, start, end, set_times, user_id, type, notification, _destroy, suggestedScheduling };
    }

    checkInvalid() {
        const start = (this.state.start || "").length < 1
        const user_id = (this.state.user_id || "").length < 1

        this.setState({ ...this.state, validations: { start, user_id } });

        return [start, user_id].includes(true) && !this.state._destroy;
    }

    dayOptions = () => {
        const days = [0, 1, 2, 3, 4, 5, 6];
        const today = moment.tz(this.props.timezone).day();
        let appointmentTimestamp;

        if (this.state.start) {
            appointmentTimestamp = moment(this.state.start, "YYYY-MM-DD");
        }

        return days.map((day, index) => {
            let timestamp;

            if (today > day) {
                let target = day + 7;
                timestamp = moment.tz(this.props.timezone).day(target)
            } else {
                timestamp = moment.tz(this.props.timezone).day(day)
            }

            let className = "special-select";
            if (appointmentTimestamp) {
                if (appointmentTimestamp.format("ddd") == timestamp.format("ddd")) {
                    className += " selected";
                }
            }
            return(
                <div
                    className={className}
                    key={index}
                    onClick={() => this.setState({ start: timestamp.format("YYYY-M-D"), set_times: true })}
                >
                    { moment(timestamp).format("ddd") }
                </div>
            )
        });
    };

    suggestionCallback = (appts) => {
        const newAppt = appts.find(appt => !appt.id);
        const apptsToUpdate = appts.filter(appt => appt.id);
        this.setState({ ...newAppt, suggestedScheduling: apptsToUpdate });
    };

    render() {
        const klass = this.props.appointment._destroy ? "display-none" : "display-flex"
        return(
            <WizardConsumer>
                {(context) => (
                    <div className={klass}>
                        <div className="width-80">
                            { !this.props.hasSuggestion &&
                                <div className="row">
                                    <div className="col-xs-12">
                                        <SuggestedTech
                                            location={context.location}
                                            callback={(user) => this.setState({ user_id: user.id })}
                                            uniqueKey={this.props._key}
                                            company={context.company}
                                            companySettings={context.companySettings}
                                            environment={context.environment}
                                            pusherKey={context.pusherKey}
                                            account={{
                                                contact_name: context.contact_name,
                                                location: context.location,
                                                time_to_service: context.time_to_service
                                            }}
                                            techs={context.serviceTechs}
                                            suggestionCallback={this.suggestionCallback}
                                            basePlan={context.basePlan}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-md-11 col-xs-10">
                                    <div className="display-flex overflow-x-scroll">
                                        <div className="special-select-wrapper">
                                            { this.dayOptions() }
                                        </div>
                                    </div>
                                    <ValidationMessage condition={this.state.validations.start} message="Service day can't be blank" />
                                </div>
                            </div>
                            <div className="row margin-10-top">
                                <div className="col-md-11 col-xs-10 form-group no-margin-bottom">
                                    <select
                                        className="form-control"
                                        onChange={(e) => this.setState({ user_id: e.target.value })}
                                        value={this.state.user_id || ""}
                                    >
                                        <option value="">Select Service Technician...</option>
                                        { this.props.serviceTechs.map(tech => {
                                            return(
                                                <option
                                                    key={tech.id}
                                                    value={tech.id}
                                                >
                                                    { tech.name }
                                                </option>
                                            )
                                        }) }
                                    </select>
                                    <ValidationMessage condition={this.state.validations.user_id} message="Service Technician can't be blank" />
                                    <label htmlFor={`notification-${this.state._key}`} className="margin-10-top no-margin-bottom">
                                        Send notification to technician:&nbsp;
                                    </label>
                                    <input
                                        id={`notification-${this.state._key}`}
                                        type="checkbox"
                                        onChange={(e) => this.setState({ notification: this.state.notification ? null : "new" })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="width-20 display-flex align-items-center justify-content-center">
                            <a
                                className="text-center"
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.removeAppointment(this.state._key)
                                } }
                            >
                                Remove Service Day
                            </a>
                        </div>
                    </div>
                )}
            </WizardConsumer>
        )
    }
}
